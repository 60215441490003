.clients-container {
  background-color: #000;
  color: #fff;
  padding: 4rem 0;
  margin-top: -1em;
  font-family: 'Acme', sans-serif;
}

.clients-content {
  text-align: center;
  margin-bottom: 5rem;
  width: 50%;
  transform: translateX(50%);
}

.clients-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.clients-content p {
  margin-bottom: 2rem;
}

.clients-logos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  justify-items: center;
  align-items: center;
  padding: 2em;
}

.clients-logos img {
  max-width: 60%;
  height: auto;
}
@media (max-width: 768px) {
  .clients-content {
    text-align: center;
    margin-bottom: 5rem;
    width: 100%;
    transform: translateX(0%);
  }
  .clients-content p {
    padding: 1em;
  }

  .clients-logos img {
    max-width: 100%;
  }
  .clients-logos {
    gap: 1rem;
    padding: 1em;
  }
}
