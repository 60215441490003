.text-banner {
  background-color: black;
  color: white;
  text-align: center;
  padding: 2em;
  font-family: 'Acme', sans-serif;
}

.text-banner p {
  width: 50%;
  transform: translateX(50%);
}
.text-banner h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .text-banner p {
    width: 100%;
    transform: translateX(0%);
    margin-bottom: 1rem;
  }
  .text-banner {
    margin-bottom: 3em;
  }
}
